import { Injectable } from "@angular/core";
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private auth_token = localStorage.getItem("access_token");

  constructor() {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const credentialsKey = "access_token";
    this.auth_token = sessionStorage.getItem(credentialsKey) || localStorage.getItem(credentialsKey);
    request = request.clone({
      setHeaders: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.auth_token}`
      }
    });

    return next.handle(request);
  }
}
