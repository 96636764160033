import { Injectable } from "@angular/core";
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Router } from "@angular/router";
import { debounceTime, Observable, Subject, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { AuthenticationService } from "@app/core";
import { ThemeService } from "@app/theme/theme.service";

@Injectable()
export class UnauthorizedInterceptor implements HttpInterceptor {
  private onLogout = new Subject<void>();

  private excludedUrls: string[] = [
    "/public/",
    "/assets/",
    "/api/v3/auth/login",
    "/api/v3/auth/refresh-token",
    "/api/v3/auth/verify-otp",
    "/api/v3/auth/generate-2fa",
    "/api/v3/auth/change_password",
    "/api/v3/auth/reset_password_req",
    "/api/v3/auth/reset_password_verify",
    "/api/v3/auth/reset_password_change"
  ];

  constructor(private router: Router, private authenticationService: AuthenticationService, private themeService: ThemeService) {
    this.onLogout.pipe(debounceTime(500)).subscribe(() => {
      this.authenticationService.logout().subscribe(() => {
        this.themeService.setTheme("light");
        this.router.navigate(["/login"], { replaceUrl: true });
      });
    });
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401 && !this.isUrlExcluded(request.url)) {
          this.onLogout.next(null);
        }
        return throwError(() => error);
      })
    );
  }

  private isUrlExcluded(url: string): boolean {
    return this.excludedUrls.some(excludedUrl => url.includes(excludedUrl));
  }
}
