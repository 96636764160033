import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {BehaviorSubject, Observable, of} from "rxjs";
import {tap} from "rxjs/operators";
import {environment} from "@env/environment";
import {Role} from "@app/roles/service/roles.model";
import {SensorAlarmEventsService} from "@app/sensor-alarm-events/sensor-alarm-events.service";

export interface Credentials {
    id: any;
    username: string;
    firstname: string;
    lastname: string;
    organizationId: number;
    isSuperAdmin: boolean;
    token: string;
    roles?: Role[];
    email: string;
    capabilities: string[];
}

export interface LoginContext {
    username: string;
    password: string;
    remember?: boolean;
}

export interface Remove2FAResponse {
    success: boolean;
}

const credentialsKey = "credentials";
const tokenKey = "access_token";

@Injectable({
    providedIn: "root"
})
export class AuthenticationService {
    private baseUrl = `${environment.ROOT_URL}/auth`;
    private _credentials = new BehaviorSubject<Credentials | null>(null);
    private partialCredentials: Credentials | null = null;
    private partiallyAuthenticated = false;

    constructor(
        private http: HttpClient,
        private sensorAlarmEventsService: SensorAlarmEventsService
    ) {
        this.loadStoredCredentials();
    }

    connectSockets(): void {
        this.sensorAlarmEventsService.connect("socket1", environment.SOCKETIO_ROOT_URL, "/sensor-events");
        this.sensorAlarmEventsService.connect("socket2", environment.SOCKETIO_ROOT_URL2);
    }

    disconnectSockets(): void {
        this.sensorAlarmEventsService.disconnect("socket1");
        this.sensorAlarmEventsService.disconnect("socket2");
    }

    private loadStoredCredentials(): void {
        const savedCredentials = sessionStorage.getItem(credentialsKey) || localStorage.getItem(credentialsKey);
        if (savedCredentials) {
            this._credentials.next(JSON.parse(savedCredentials));
        }
        const savedToken = sessionStorage.getItem(tokenKey) || localStorage.getItem(tokenKey);
        if (savedToken) {
            this.setToken(savedToken, true);
        }
    }

    public login(context: LoginContext): Observable<any> {
        return this.http.post<Credentials>(`${this.baseUrl}/login`, {
            username: context.username?.toLowerCase(),
            password: context.password
        }).pipe(
            tap((res) => {
                this.setCredentials(res, true);
                if (res.enable_2fa) {
                    this.partiallyAuthenticated = true;
                    this.partialCredentials = res;
                } else {
                    this.connectSockets();
                }
            })
        );
    }

    public save2FA(secret: string): Observable<{ success: boolean }> {
        return this.http.post<{ success: boolean }>(`${this.baseUrl}/save-2fa`, {secret});
    }

    public generate2FA(): Observable<{ secret: string; qrCodeImageUrl: string }> {
        return this.http.post<{ secret: string; qrCodeImageUrl: string }>(`${this.baseUrl}/generate-2fa`, {});
    }

    public remove2FA(userId: number): Observable<Remove2FAResponse> {
        return this.http.post<Remove2FAResponse>(
            `${this.baseUrl}/remove-2fa`,
            {userId}
        );
    }

    public verifyCode(token: string): Observable<any> {
        return this.http.post(`${this.baseUrl}/verify-otp`, {token}).pipe(
            tap((res: any) => {
                this.setToken(res.token, true);
                this.setCredentials({...res, token: res.token}, true);
                this.partiallyAuthenticated = false;
                this.connectSockets();
            })
        );
    }

    public logout(): Observable<boolean> {
        this.clearStoredCredentials();
        this._credentials.next(null);
        this.disconnectSockets();
        return of(true);
    }

    public isAuthenticated(): boolean {
        return !this.isPartiallyAuthenticated() && Boolean(this._credentials.value);
    }

    public isPartiallyAuthenticated(): boolean {
        return this.partiallyAuthenticated;
    }

    private setCredentials(credentials: Credentials, remember: boolean = true): void {
        this._credentials.next(credentials);
        const storage = remember ? localStorage : sessionStorage;
        storage.setItem(credentialsKey, JSON.stringify(credentials));
        this.setToken(credentials.token, remember);
    }

    private setToken(token: string, remember: boolean = true): void {
        const storage = remember ? localStorage : sessionStorage;
        storage.setItem(tokenKey, token);
    }

    private clearStoredCredentials(): void {
        sessionStorage.removeItem(credentialsKey);
        localStorage.removeItem(credentialsKey);
        sessionStorage.removeItem(tokenKey);
        localStorage.removeItem(tokenKey);
    }

    public checkCapability(capabilityName: string): boolean {
        if (!capabilityName) {
            return true;
        }
        return this.isSuperAdmin || this.capabilities.includes(capabilityName);
    }

    get capabilities(): string[] {
        return this._credentials.value?.capabilities || [];
    }

    get credentials(): Credentials | null {
        return this._credentials.value;
    }

    get organizationId(): number | null {
        return this._credentials.value?.organizationId || null;
    }

    get isSuperAdmin(): boolean {
        return this._credentials.value?.isSuperAdmin || false;
    }
}
